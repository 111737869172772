<template>
  <b-modal
    @shown="getAllPlans"
    size="xl"
    @hidden="hiddenModal"
    :no-close-on-esc="true"
    modal-class="full-modal"
    hide-footer
    hide-header
    id="planList"
    title="BootstrapVue"
  >
    <div>
      <div class="d-flex justify-content-end mb-4">
        <a @click.prevent="close" href="#" class="d-inline-block">
          <v-icon size="2x" icon="times" />
        </a>
      </div>
      <div v-if="isSubPlan">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <a
                href="#"
                @click.prevent="cancelSubsPlan"
                class="mb-3 d-inline-block font-weight-bold"
                >Back to Plans</a
              >
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <div class="plan-list-item m-0 w-100">
                <div class="plan-list-item-head">
                  <h3 class="plan-list-item-title">
                    {{ selectPlan.name }}
                  </h3>
                  <p>Plan</p>
                </div>
                <div class="plan-list-item-content">
                  <div class="plan-price justify-content-between">
                    <div class="d-flex align-items-end">
                      <div class="d-flex">
                        <span class="currency">€</span>
                        <span class="price">{{
                          selectPlan.info.prices["EUR"]
                        }}</span>
                      </div>
                      <span>/Monthly</span>
                    </div>
                    <div class="plan-discount d-none">€</div>
                  </div>
                  <p class="price-type mt-4">
                    Billed {{ selectPlan.name }}
                    {{
                      selectPlan.type === "yearly"
                        ? (selectPlan.info.prices["EUR"] * 12).toFixed(2)
                        : name === "quarterly"
                        ? (selectPlan.info.prices["EUR"] * 6).toFixed(2)
                        : selectPlan.info.prices["EUR"]
                    }}
                  </p>
                  <div class="plan-list-item-price-text">
                    For users that are ready to control and extend their reach.
                  </div>
                </div>
                <ul class="plan-detail">
                  <li v-for="spec in selectPlan.info.specs">{{ spec }}</li>
                </ul>
              </div>
            </div>
            <div class="col-8" style="min-height: 750px">
              <loading :loading="loading3d"></loading>
              <iframe
                @load="onload3d"
                class="w-100 h-100"
                :src="paddle3dUrl"
                frameborder="0"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isSubPlan">
        <div class="d-flex justify-content-between">
          <h3 class="semi-title">Choose Plan</h3>
          <div>
            <button
              class="btn btn-sm mr-3"
              :class="
                selectType === 'single' ? 'btn-primary' : 'btn-outline-primary'
              "
              @click="changeType('single')"
            >
              Single
            </button>
            <button
              class="btn btn-sm"
              :class="
                selectType === 'enterprise'
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              "
              @click="changeType('enterprise')"
            >
              Enterprise
            </button>
          </div>
        </div>
        <div class="plan-list">
          <loading :loading="loading" />
          <b-tabs
            v-if="!loading && selectType === 'single'"
            nav-class="planTab"
            content-class="mt-3"
          >
            <b-tab
              v-for="(value, name) in allPlans"
              :key="value.id"
              v-if="name !== '*'"
              :title="name"
              :active="name === 'yearly'"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-9 d-flex">
                    <div
                      v-for="item in allPlans['*']"
                      :key="item.id"
                      class="plan-list-item"
                    >
                      <div class="plan-list-item-head">
                        <h3 class="plan-list-item-title">
                          {{ item.name }}
                        </h3>
                        <p>Plan</p>
                      </div>
                      <div class="plan-list-item-content">
                        <div class="plan-price justify-content-between">
                          <div class="d-flex align-items-end">
                            <div class="d-flex">
                              <span class="currency">€</span>
                              <span class="price">0</span>
                            </div>
                            <span>/Monthly</span>
                          </div>
                        </div>
                        <p class="price-type mt-4">Free</p>
                        <div class="plan-list-item-price-text">
                          For users that are ready to control and extend their
                          reach.
                        </div>
                      </div>
                      <button
                        disabled="disabled"
                        class="btn btn-primary w-100 checkout-btn"
                      >
                        Register Free
                      </button>
                      <ul class="plan-detail">
                        <li v-for="spec in item.info.specs">{{ spec }}</li>
                      </ul>
                    </div>
                    <div v-for="item in value" class="plan-list-item">
                      <div class="plan-list-item-head">
                        <h3 class="plan-list-item-title">
                          {{ item.name }}
                        </h3>
                        <p>Plan</p>
                      </div>
                      <div class="plan-list-item-content">
                        <div class="plan-price justify-content-between">
                          <div class="d-flex align-items-end">
                            <div class="d-flex">
                              <span class="currency">€</span>
                              <span class="price">{{
                                item.info.prices["EUR"]
                              }}</span>
                            </div>
                            <span>/Monthly</span>
                          </div>
                          <div class="plan-discount">
                            {{ (item.info.prices["EUR"] * 1.5).toFixed(2) }} €
                          </div>
                        </div>
                        <p class="price-type mt-4">
                          Billed {{ name }}
                          {{
                            name === "yearly"
                              ? (item.info.prices["EUR"] * 12).toFixed(2)
                              : name === "quarterly"
                              ? (item.info.prices["EUR"] * 6).toFixed(2)
                              : item.info.prices["EUR"]
                          }}
                        </p>
                        <div class="plan-list-item-price-text">
                          For users that are ready to control and extend their
                          reach.
                        </div>
                      </div>
                      <button
                        :disabled="item.level <= myPlanLevel"
                        @click="subscribePlan(item)"
                        class="btn btn-primary w-100 checkout-btn"
                      >
                        {{
                          myPlanLevel === item.level ? "Your Plan" : "Subscribe"
                        }}
                      </button>
                      <ul class="plan-detail">
                        <li v-for="spec in item.info.specs">{{ spec }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
          <b-tabs
            v-if="!loading && selectType === 'enterprise'"
            nav-class="planTab"
            active-nav-item-class="w-100"
            nav-wrapper-class="w-100"
            content-class="mt-3"
          >
            <b-tab title="Enterprise Membership Plans" active>
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-9 d-flex">
                    <div v-for="item in enterprisePlans" class="plan-list-item">
                      <div class="plan-list-item-head">
                        <h3 class="plan-list-item-title">
                          {{ item.name }}
                        </h3>
                        <p>Plan</p>
                      </div>
                      <div class="plan-list-item-content">
                        <div class="plan-price justify-content-between">
                          <div class="d-flex align-items-end">
                            <div class="d-flex">
                              <span class="currency">€</span>
                              <span class="price">{{ item.price }}</span>
                            </div>
                            <span>/Yearly</span>
                          </div>
                          <div class="plan-discount d-none">€</div>
                        </div>
                        <p class="price-type mt-4">
                          {{
                            item.name === "Starter"
                              ? "Free"
                              : "Billed Yearly at " + item.price
                          }}
                        </p>
                        <div class="plan-list-item-price-text">
                          For users that are ready to control and extend their
                          reach.
                        </div>
                      </div>
                      <a
                        href="mailto:support@connectter.com"
                        class="btn btn-primary w-100 checkout-btn"
                      >
                        Contact Us
                      </a>
                      <ul class="plan-detail">
                        <li v-for="spec in item.specs">{{ spec }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import getAllPlans from "@/graphql/public/getAllPlans.graphql";
import loading from "@/components/global/loading";
import sendSubsPlan from "@/graphql/me/sendSubsPlan.graphql";
import _ from "lodash";

export default {
  props: {
    myPlanLevel: {
      type: Number,
      default: null,
      required: true,
    },
  },
  components: {
    loading,
  },
  name: "planListModal.vue",
  data() {
    return {
      loading3d: true,
      paddle3dUrl: null,
      selectPlan: null,
      isSubPlan: false,
      selectType: "single",
      loading: true,
      allPlans: null,
      enterprisePlans: [
        {
          name: "Starter",
          price: 0,
          discount: 0,
          specs: [
            "5 Account",
            "3 Product Quotes (per account)",
            "3 Product Requests (per account)",
            "300 km Radius to Match",
          ],
        },
        {
          name: "Pro",
          price: 910,
          discount: 1020,
          specs: [
            "5 Account",
            "15 Product Quotes (per account)",
            "15 Product Requests (per account)",
            "1000 km Radius to Match",
          ],
        },
        {
          name: "Premium",
          price: 1290,
          discount: 1440,
          specs: [
            "5 Account",
            "Unlimited Product Quotes",
            "Unlimited Buying Request",
            "Unlimited Radius to Match (Search by City)",
          ],
        },
      ],
    };
  },
  methods: {
    onload3d() {
      this.loading3d = false;
    },
    async cancelSubsPlan() {
      this.loading3d = true;
      this.isSubPlan = false;
      this.selectPlan = null;
    },
    async subscribePlan(plan) {
      try {
        let data = await this.$apollo.mutate(sendSubsPlan, {
          plan_id: plan.id,
        });
        let response = data("**.subscribe");
        if (response.new) {
          this.paddle3dUrl = response.url;
          this.selectPlan = plan;
          this.isSubPlan = true;
        }
        console.log(response);
      } catch (e) {
        console.log(e);
      }
    },
    hiddenModal() {
      this.loading3d = true;
      this.selectType = "single";
      this.isSubPlan = false;
      this.selectPlan = null;
    },
    reverseObject(object) {
      let newObject = {};
      let keys = [];

      for (let key in object) {
        keys.push(key);
      }

      for (let i = keys.length - 1; i >= 0; i--) {
        let value = object[keys[i]];
        newObject[keys[i]] = value;
      }

      return newObject;
    },
    async getAllPlans() {
      try {
        let data = await this.$apollo.query(getAllPlans);
        let response = data("**.plans");
        let plans = _.groupBy(response, (item) => item.type);
        this.allPlans = this.reverseObject(plans);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$bvModal.hide("planList");
    },
    changeType(type) {
      this.selectType = type;
    },
  },
};
</script>
