<template>
  <div class="container">
    <div v-if="loading" class="row">
      <div class="col-12">
        <loading :loading="loading" />
      </div>
    </div>
    <div
      v-if="!loading"
      class="row no-gutters align-items-stretch profile-setting-plan"
    >
      <div class="col-4">
        <div class="profile-setting-plan-left">
          <h3 class="profile-setting-plan-left-title text-capitalize">
            Currenddt Plan 
          </h3>
          <span class="semi-title"> {{ myPlan.name }}</span>

          <ul>
            <li v-for="(item, index) in myPlan.info.specs" :key="index">
              <img
                src="/img/tick-green.svg"
                style="margin-right: 17px"
                class="img-fluid"
                alt=""
              />{{ item }}
            </li>
          </ul>
          <button
            v-if="myPlan.name !== 'COMMUNITY' && !myPlan.cancelled"
            @click="cancelPlanModal"
            class="btn btn-outline-secondary border-white text-white mt-5 px-5"
          >
            Cancel Plan
          </button>
          <span v-if="myPlan.cancelled" class="text-white mt-5 d-block"
            >Your plan cancelled, cancel date: {{ myPlan.expired_at }}</span
          >
          <a class="change-plan-button" @click="seeOtherPlan()">Change Plan</a>
          <p class="change-redirect">
            You can easily go back to your initial plan or cancel. <br />
            Learn how to change
          </p>
        </div>
      </div>
      <div class="col-8">
        <div class="profile-setting-plan-right">
          <div class="profile-setting-plan-right-item">
            <h3 class="semi-title mb-4">Payment Details</h3>
            <p>
              Your next billing period is 06/16/2022 and your next billing
              amount is $ 24 / month.
            </p>
          </div>
          <div class="row your-card">
            <div class="col-md-8">
              <h4>Your card ending with 5553</h4>
              <p>Last date of use is 06/2023</p>
            </div>
            <div class="col-md-4 text-right">
              <a href="" class="change-method-bttn">Change Method</a>
            </div>
          </div>
          <div class="profile-setting-plan-right-item mb-5">
            <h3 class="semi-title mb-4">Upgrade</h3>
          </div>
          <div class="profile-setting-plan-right-item">
            <button @click="openPlanListModal" class="btn btn-primary">
              Upgrade
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :hide-header-close="cancelLoading"
      :no-close-on-backdrop="cancelLoading"
      :no-close-on-esc="cancelLoading"
      centered
      id="cancelPlanModal"
      hide-footer
      header-bg-variant="danger"
      header-text-variant="white"
      title="Cancel Plan"
    >
      <p
        v-if="!cancelLoading && !cancelled"
        class="my-4 text-center semi-title"
      >
        Are you sure you want to cancel your Pro Plan?
      </p>
      <loading :loading="cancelLoading" />
      <div
        v-if="!cancelLoading && !cancelled"
        class="d-flex justify-content-center"
      >
        <div class="d-flex flex-column w-75">
          <button
            @click.prevent="cancelPlan"
            class="btn btn-primary mt-4 mb-3 btn-sm py-2"
          >
            Cancel
          </button>
          <button
            @click="seeOtherPlan"
            class="btn btn-outline-primary mb-4 btn-sm py-2"
          >
            See Other Plans
          </button>
        </div>
      </div>
      <p v-if="!cancelLoading && cancelled" class="text-center my-3 mb-4">
        Your plan cancelled, cancel date: {{ cancelPlanDate }}
      </p>
      <button
        @click="refreshPage"
        v-if="!cancelLoading && cancelled"
        class="btn btn-primary btn-block"
      >
        Close
      </button>
    </b-modal>
    <plan-list-modal v-if="myPlan !== null" :myPlanLevel="myPlan.level" />
  </div>
</template>

<script>
import planListModal from "@/components/app/profile/planListModal";
import getMyPlan from "@/graphql/me/profile/getMyPlan.graphql";
import loading from "@/components/global/loading";
import cancelPlan from "@/graphql/me/profile/cancelPlan.graphql";
export default {
  components: {
    planListModal,
    loading,
  },
  data() {
    return {
      myPlan: null,
      loading: true,
      cancelLoading: false,
      cancelPlanDate: null,
      cancelled: false,
    };
  },
  methods: {
    refreshPage() {
      this.cancelLoading = true;
      location.reload();
    },
    async cancelPlan() {
      try {
        this.cancelLoading = true;
        let data = await this.$apollo.mutate(cancelPlan);
        let response = data("**.cancel");
        this.cancelPlanDate = response;
        this.cancelled = true;
      } catch (e) {
        
        this.cancelled = false;
          return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.cancelLoading = false;
      }
    },
    openPlanListModal() {
      this.$bvModal.show("planList");
    },
    cancelPlanModal() {
      this.$bvModal.show("cancelPlanModal");
    },
    seeOtherPlan() {
      this.$bvModal.hide("cancelPlanModal");
      this.openPlanListModal();
    },
    async getMyPlan() {
      try {
        let data = await this.$apollo.query(getMyPlan);
        let response = data("**.get");
        console.log(response);
        this.myPlan = response.plan;
      } catch (e) {
       
        return this.$bvToast.toast(this.$t(e.message), {
         title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getMyPlan();
  },
};
</script>